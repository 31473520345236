import React from 'react';
import MediaQuery from 'react-responsive';
import PageLayout from 'components/PageLayout';
import SEO from 'components/seo';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { sizes } from 'styles';

const ConstructionUpdatesPage = () => (
  <PageLayout restrictHeight={false}>
    <SEO title="Brill Place Tower Construction Works" description="Brill Place Tower (also known as Grand Central Apartments) is an exciting new 22 storey development of 68 residential apartments. The Tower will also accommodate a single storey basement, ground floor residential lobby and public café with views over the Purchase Street Open Space." />
    <MediaQuery minWidth={sizes.TABLE_MAX_WIDTH + 1}>
      <Desktop />
    </MediaQuery>
    <MediaQuery maxWidth={sizes.TABLE_MAX_WIDTH}>
      <Mobile />
    </MediaQuery>
  </PageLayout>
);

export default ConstructionUpdatesPage;
